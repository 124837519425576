import * as React from 'react'
import { Typography, FormControl, FormGroup, FormControlLabel } from '@mui/material'
import Checkbox from '@mui/material/Checkbox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Box } from '@mui/system'
import { LABELS } from '../config/labels'


const FilterComponent = ({ options, handleChange, checked }) => {
    return (
        <Box sx={{ backgroundColor: '#EDEDED', borderRadius: '5px',  }}>
            <FormControl sx={{ padding: '19px' }}>
                <FormGroup key="b1">
                    <Typography component='h2' variant='h6' sx={{ fontWeight: '700' }}>Marka</Typography>
                    {options['brands'].map((brand, index) => {
                        return (
                            <FormControlLabel
                                key={index}
                                control={
                                    <Checkbox
                                        checked={checked['brands']?.indexOf(brand) > -1}
                                        onChange={e => handleChange(e, 'brands')} 
                                        name={brand}
                                        checkedIcon={<CheckBoxIcon
                                            sx={{
                                                color: "#82BF37",
                                            }}
                                        />}
                                    />
                                }
                                label={brand}
                            />
                        )
                    })}
                </FormGroup>

                <FormGroup key="b2" sx={{ marginTop: '1rem' }}>
                    <Typography component='h2' variant='h6' sx={{ fontWeight: '700' }}>Cinsi</Typography>
                    {options['types'].map((type, index) => {
                        return (
                            <FormControlLabel
                                key={index}
                                control={
                                    <Checkbox checked={checked['types']?.indexOf(type) > -1} onChange={e => handleChange(e, 'types')} name={type}
                                        checkedIcon={<CheckBoxIcon
                                            sx={{
                                                color: "#82BF37"
                                            }}
                                        />}
                                    />
                                }
                                label={type}
                            />
                        )
                    })}
                </FormGroup >
                {options.hasOwnProperty('petTypes') &&
                    <FormGroup key="b3" sx={{ marginTop: '1rem' }}>
                        <Typography component='h2' variant='h6' sx={{ fontWeight: '700' }}>Evcil Hayvan</Typography>
                        {options['petTypes'].map((type, index) => {
                            return (
                                <FormControlLabel
                                    key={index}
                                    control={
                                        <Checkbox checked={checked['petTypes']?.indexOf(type) > -1} onChange={e => handleChange(e, 'petTypes')} name={type}
                                            checkedIcon={<CheckBoxIcon
                                                sx={{
                                                    color: "#82BF37"
                                                }}
                                            />}
                                            disabled ={ options['petTypes'].length===1}
                                        />
                                    }
                                    label={LABELS[type]}
                                />
                            )
                        })}
                    </FormGroup>
                }
                {options.hasOwnProperty('ages') &&
                    <FormGroup key="b4" sx={{ marginTop: '1rem' }}>
                        <Typography component='h2' variant='h6' sx={{ fontWeight: '700' }}>Yaş Grubu</Typography>
                        {options['ages'].map((age, index) => {
                            return (
                                <FormControlLabel
                                    key={index}
                                    control={
                                        <Checkbox checked={checked['ages']?.indexOf(age) > -1} onChange={e => handleChange(e, 'ages')} name={age}
                                            checkedIcon={<CheckBoxIcon
                                                sx={{
                                                    color: "#82BF37"
                                                }}
                                            />}
                                        />
                                    }
                                    label={LABELS[age]}
                                />
                            )
                        })}
                    </FormGroup>
                }
                {(options.hasOwnProperty('breeds') && options['breeds'].length > 0) &&
                    <FormGroup key="b5" sx={{ marginTop: '1rem' }}>
                        <Typography component='h2' variant='h6' sx={{ fontWeight: '700' }}>Irklar</Typography>
                        {options['breeds'].map((breed, index) => {
                            
                            return (
                                <FormControlLabel
                                    key={index}
                                    control={
                                        <Checkbox checked={checked['breeds']?.indexOf(breed) > -1} onChange={e => handleChange(e, 'breeds')} name={breed}
                                            checkedIcon={<CheckBoxIcon
                                                sx={{
                                                    color: "#82BF37"
                                                }}
                                            />}
                                        />
                                    }
                                    label={LABELS[breed]}
                                />
                            )
                        })}
                    </FormGroup>
                }
                {(options.hasOwnProperty('features') && options['features'].length > 0) &&
                    <FormGroup key="b6" sx={{ marginTop: '1rem' }}>
                        <Typography component='h2' variant='h6' sx={{ fontWeight: '700' }}>Özellikler</Typography>
                        {options['features'].map((feature, index) => {
                            return (
                                <FormControlLabel
                                    key={index}
                                    control={
                                        <Checkbox checked={checked['features']?.indexOf(feature) > -1} onChange={e => handleChange(e, 'features')} name={feature}
                                            checkedIcon={<CheckBoxIcon
                                                sx={{
                                                    color: "#82BF37"
                                                }}
                                            />}
                                        />
                                    }
                                    label={feature}
                                />
                            )
                        })}
                    </FormGroup>
                }
            </FormControl>
        </Box>
    )
}

export default FilterComponent