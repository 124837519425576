const pathPrefix = {
    "tr-TR": "",
    "en-EU": "en"
  }
  const pathName = {
    "tr-TR": "urunler",
    "en-EU": "products"
  }
/*   const type = {
    "tr-TR": {
      "Kuru Mama": 'kuru-mamalar',
      "Yaş Mama": 'yas-mamalar',
      "Ödül": 'oduller',
      "Vitamin ve Gıda Takviyesi": "vitamin-ve-gida-takviyeleri"
    },
    "en-EU": {
      "Kuru Mama": 'dry-food',
      "Yaş Mama": 'wet-food'
    },
  } */
  const for_cats = {
    "tr-TR": {
      true: 'kedi',
      false: 'kopek'
    },
    "en-EU": {
      true: 'cat',
      false: 'dog'
    },
  }

  function createProductPath (product) {
    const path = `${pathPrefix[product.node_locale]}/${pathName[product.node_locale]}/${for_cats[product.node_locale][product.for_cats]}/${product.brand.slug}-${product.slug}`
    return(path)
  }
  module.exports.createProductPath = createProductPath