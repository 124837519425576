import * as React from 'react'
import { useState } from 'react'
import { Divider, Grid, Pagination, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import FilterComponent from './sidebar-filter'
import ProductCard from './product-card'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';





const getFilterModel = (products) => {
    let brands = []
    let types = []
    let ages = []
    let breeds = []
    let features = []
    let petTypes = []
    products.forEach(product => {
        brands.push(product?.brand?.brandName)
        product.type?.forEach(type => types.push(type))
        product.ageGroup?.forEach(age => ages.push(age))
        product.breeds?.forEach(breed => breeds.push(breed))
        product.special_care?.forEach(feature => features.push(feature.name))
        petTypes.push(product.for_cats ? 'cats' : 'dogs')
    });

    return { brands: [...new Set(brands)], types: [...new Set(types)], ages: [...new Set(ages)].sort(), breeds: [...new Set(breeds)], features: [...new Set(features)], petTypes: [...new Set(petTypes)] };
}

const filterPrdcts = (products, filter) => {

    let petTypes = []
    if (filter?.petTypes) {
        if (filter.petTypes.includes('cats')) {
            petTypes.push(true)
        }
        if (filter.petTypes.includes('dogs')) {
            petTypes.push(false)
        }

    }
    const filteredByBrands = (filter.brands.length > 0) ? products.filter(product => filter.brands.includes(product.brand.brandName)) : [...products]
    const filteredByTypes = (filter.types.length > 0) ? filteredByBrands.filter(product => filter.types.some(r => product.type.includes(r))) : [...filteredByBrands]
    const filteredByPetTypes = (petTypes.length > 0) ? filteredByTypes.filter(product => petTypes.includes(product.for_cats)) : [...filteredByTypes]
    const filteredByBreeds = (filter.breeds.length > 0) ? filteredByPetTypes.filter(product => filter.breeds.some(r => product.breeds?.includes(r))) : [...filteredByPetTypes]
    const filteredByAgeGroups = (filter.ages.length > 0) ? filteredByBreeds.filter(product => filter.ages.some(r => product.ageGroup.includes(r))) : [...filteredByBreeds]

    const filteredByFeature = (filter.features?.length > 0) ? filteredByAgeGroups.filter(product => filter.features.some(r => (product.special_care?.filter(feature => (feature.name === r)).length > 0))) : [...filteredByAgeGroups]

    return (filteredByFeature)
}

const useStyles = makeStyles(theme => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'row',


    },
    productListWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: '2%',
        width: '100%',
        justifyContent: 'space-around'



    },
    hiddenOnMobile: {

        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    hiddenOnDesktop: {

        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    }
}))


const ProductList = ({ products, checked, handleChange, flipPage }) => {
    /*     const initialFilter = {
            brands: [],
            types: [],
            ages: [],
            breeds: [],
            features: [],
            petTypes: [],
          
        }
       
        
        const filter = Object.assign({},initialFilter,filterProps) */
    const [width, setWidth] = useState(0)
    const [renderCount, setRenderCount] = useState(0)
    const [filteredProducts, setFilteredProducts] = useState(products)
    const classes = useStyles()
    const filterModel = getFilterModel(products)
    const itemsPerPage = 9
    /*     const [checked, setChecked] = useState(filter)
        
    
        const handleChange = (event, group) => {
            let checkedOptn = [...checked[group]]
            setChecked({
                ...checked,
                [group]: checkedOptn.indexOf(event.target.name) > -1 ? checkedOptn.filter(item => item !== event.target.name) :
                    [...checkedOptn, event.target.name]
    
            })
        } */

    React.useEffect(() => {
        if (typeof window === 'undefined') return;
        setWidth(window.innerWidth);
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize)
        };
    });

    React.useEffect(() => {
        const filtered = filterPrdcts(products, checked)
        setFilteredProducts(filtered)
    }, [checked, products])

    React.useEffect(() => {
        let count = renderCount
        setRenderCount(count + 1)
        return () => { console.log('unmounted') }
    }, [width])



    return (
        width >0 && 
        <Grid className={classes.wrapper} container >
            <Grid item lg={3} sx={{ paddingTop: '10px', display: width < 900 ? 'none' : '' }} key='item1'>
                <FilterComponent options={filterModel} handleChange={handleChange} checked={checked} />
            </Grid>
            <Grid item xs={12} sx={{ display: width >= 900 ? 'none' : '' }} key='item2'>
                <FilterAccordion options={filterModel} handleChange={handleChange} checked={checked} />
            </Grid>
            <Grid item sm={12} md={7} lg={8} sx={{ paddingTop: '10px' }} key='item3'>
                <Grid className={classes.productListWrapper}>
                    {filteredProducts?.slice((checked.page - 1) * itemsPerPage, checked.page * itemsPerPage).map((product, index) => {
                        return (
                            <React.Fragment key={index}>
                                <ProductCard product={product} width={width} key={index} />
                                {(width <= 600) && <Divider variant="fullWidth" sx={{ width: '100%' }} key={`div-${index}`} />}
                            </React.Fragment>
                        )
                    })}
                </Grid>
                <Grid><Pagination count={(filteredProducts.length - filteredProducts.length % itemsPerPage) / itemsPerPage + 1} onChange={flipPage} page={parseInt(checked.page)} /></Grid>
            </Grid>

        </Grid>

    )
}

const FilterAccordion = ({ options, handleChange, checked }) => {
    return (
        <Accordion >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>Ürün Filtrele</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <FilterComponent options={options} handleChange={handleChange} checked={checked} />
            </AccordionDetails>
        </Accordion>
    )
}




export default ProductList