export const LABELS = {
    'cats':'Kedi',
    'dogs':'Köpek',
    '0-12 months':'0-12 ay',
    '1-10 years':'1-10 yaş',
    '11+':'11 yaş ve üzeri',
    'medium': 'Orta (maks. 25kg)',
    'large': 'Büyük (maks. 45kg)',
    'small':'Küçük (maks. 10kg)',
    'giant':'Dev (45kg üzeri)',
  
}