import * as React from 'react'
import { withStyles } from '@mui/styles';
import Layout from '../../components/layout'
import ProductList from '../../components/product-list'
import qs from 'qs'
import { navigate } from 'gatsby'
import Seo from '../../components/seo'






const ProductPage = (props) => {
  const { products, breadcrumb } = props.pageContext
  const { location, path } = props
  const filter = qs.parse((location.search), { ignoreQueryPrefix: true })
  const initialFilter = {
    brands: [],
    types: [],
    ages: [],
    breeds: [],
    features: [],
    petTypes: [],
    page: 1
  }

React.useEffect(()=> {
return ()=> console.log('unmounting Productsearch')
},[])


  const filtered = Object.assign({}, initialFilter, filter)
  const handleChange = (event, group) => {
    let checkedOptn = [...filtered[group]]
    let optionsSelected = {
      ...filtered,
      [group]: checkedOptn.indexOf(event.target.name) > -1 ? checkedOptn.filter(item => item !== event.target.name) :
        [...checkedOptn, event.target.name],
      page: 1

    }


    let queryString = qs.stringify(optionsSelected, { format: 'RFC1738', addQueryPrefix: true, encodeValuesOnly: true, arrayFormat: 'indices' })
    const newPath = `${path}${queryString}`
    navigate(newPath)
  }
  const handleFlip = (event, value) => {
    let optionsSelected = { ...filtered, page: value }
    let queryString = qs.stringify(optionsSelected, { format: 'RFC1738', addQueryPrefix: true, encodeValuesOnly: true, arrayFormat: 'indices' })
    const newPath = `${path}${queryString}`
    navigate(newPath)
  }



  const pageTitle = () => {
    if (location.pathname === '/urunler/kedi') { return 'Kedi Ürünlerimiz' }
    if (location.pathname === '/urunler/kopek') { return 'Köpek Ürünlerimiz' }
    return 'Ürünlerimiz'
  }

  return (
    <Layout location={location} pageTitle={pageTitle()} pageContext={{breadcrumb: props.pageContext.breadcrumb}}>
      <Seo title={pageTitle()} />
      <ProductList products={products} checked={filtered} handleChange={handleChange} flipPage={handleFlip} />
    </Layout>


  )
}


export default withStyles({}, { withTheme: true })(ProductPage)