import * as React from 'react'
import { Link } from 'gatsby'
import { Card, CardMedia, Typography, CardContent, CardHeader } from '@mui/material'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { createProductPath } from '../utilities/create-path'
import { makeStyles } from '@mui/styles'
import Packages from './packages'
import {turkishToUpper} from '../utilities/formatting'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flex: '0 1 30%',
        flexDirection: 'column',
        height: '100%',
        alignItems: 'center',
        border: "none",
        justifyContent: 'space-between;',
        boxShadow: "none!important;",
        '&:hover': {
            boxShadow: "0px 1px 1px 1px",
            border: '1px solid rgba(0, 0, 0, 0.15);'

        },
        [theme.breakpoints.down('sm')]: {
            flex: '1 1 100%',

        }

    },
    cover: {
        width: 'auto',
        maxHeight: '100%',
        objectFit: 'cover',
        zIndex: 10,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: 'auto'
        }

    },
    imageWrapper: {
        display: 'flex!important;',
        flexDirection: 'column;',
        justifyContent: 'center!important;',  /* Centering y-axis */
        height: '305px',
        maxHeight: '65%',
        position: 'relative',

        [theme.breakpoints.down('sm')]: {
            height: '100%',

        }
    },
    fullWParagraph: {
        display: 'flex',
        justifyContent: 'center',



    }
}))

const ProductCard = ({ product,width }) => {
    const isMd = width < 900
    const isLg = width <= 1200
    const isSm = width <= 600
    const classes = useStyles()
    const path = createProductPath(product)
    const productImage = getImage(product.packagePhoto?.localFile)


    return (
        <Card sx={{
            display: 'flex',
            flex: isSm? '0 0 100%': isMd? '0 0 40%':isLg?'1 1 45%':'0 1 30%',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center',
            border: "none",
            justifyContent: 'space-between;',
            boxShadow: "none!important;",
            '&:hover': {
                boxShadow: "0px 1px 1px 1px",
                border: '1px solid rgba(0, 0, 0, 0.15);'

            },
            
        }}>
            <CardMedia sx={{
                display: 'flex!important;',
                flexDirection: 'column;',
                justifyContent: 'center!important;',  /* Centering y-axis */
                height: isSm? '100%':'305px',
                maxHeight: '65%',
                position: 'relative',
                
            }}>
                <Link to={path}>
                    {
                        productImage && <GatsbyImage image={productImage} alt={product.name} sx={{
                            width: isSm?'100%':'auto',
                            maxHeight: '100%',
                            objectFit: 'cover',
                            zIndex: 10,
           
                        }} />
                    }
                </Link>
            </CardMedia>
            <CardContent>
                <CardHeader title={<Typography sx={{ fontWeight: '700', fontSize: '1rem', lineHeight: '1.2rem', textAlign: 'center', textTransform: 'uppercase' }}>{`${product.brand.brandName} ${turkishToUpper(product.name)}`}</Typography>} disableTypography />
                <div className={classes.fullWParagraph}>
                    <Packages skus={product?.sku} />
                </div>
            </CardContent>
        </Card>
    )

}
export default ProductCard